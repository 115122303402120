import { css } from '@emotion/react'
import { SearchAuctionBidResponse } from 'api/driverama/auctions/auctionsBids'
import { Divider } from 'driverama-core/components/divider/Divider'
import { Dropdown } from 'driverama-core/components/dropdown/Dropdown'
import { Flex } from 'driverama-core/components/Flex'
import IconCrossCircle from 'images/icons/IconCrossCircle.svg'
import DotsIcon from 'images/icons/IconDots.svg'
import IconDownload from 'images/icons/IconDownload.svg'
import IconExternalLink from 'images/icons/IconExternalLink.svg'
import IconMail from 'images/icons/IconMail.svg'
import IconPlus from 'images/icons/IconPlus.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AuctionListData } from 'sections/auctions/Auctions.utils'
import { useEscalationEmailButton } from 'sections/auctions/detail/escalationEmail/EscalationEmail.utils'
import { useExportButton } from 'sections/auctions/detail/export/ExportButton.utils'
import { SButtonCircle } from './ActionHeaderActionMenu.styled'
import { ActionMenuItem } from './AuctionHeaderActionMenuItem'

type Props = {
  carId: string
  state: AuctionListData['state']
  highestBid?: SearchAuctionBidResponse['content'][number]
  openEndModal: () => Promise<void>
  onPlaceBidClick: () => void
  openPricingApp: () => void
  plannedEndAt?: string | null
  plannedEndAtDate?: Date
  canEndAuction: boolean
  canPlaceBid: boolean
  opportunityId?: string
}

export function AuctionHeaderActionMenu({
  carId,
  highestBid,
  state,
  openEndModal,
  onPlaceBidClick,
  openPricingApp,
  canEndAuction,
  canPlaceBid,
  opportunityId
}: Props) {
  const { t } = useTranslation(['core', 'auction'])
  const [open, setOpen] = useState(false)
  const { isLoading, exportPdf } = useExportButton(carId)
  const { sendEscalationEmail } = useEscalationEmailButton(opportunityId)

  const openMenu = () => setOpen(true)
  const closeMenu = () => setOpen(false)

  return (
    <Dropdown
      isFullHeight
      isInModal
      onClose={closeMenu}
      onOpen={openMenu}
      placement="bottom"
      css={css`
        @media print {
          display: none;
        }
      `}
      trigger={
        <SButtonCircle variant="outline" isOpen={open}>
          <DotsIcon />
        </SButtonCircle>
      }
    >
      <Flex variant="column" gap={1}>
        {state === 'IN_PROGRESS' && (
          <>
            {!highestBid && (
              <ActionMenuItem
                label={t('auction:header_button_pricing_app')}
                onClick={openPricingApp}
                Icon={IconExternalLink}
                disabled={!canPlaceBid}
                tooltip={
                  !canPlaceBid
                    ? t('auction:header_place_bid_missing_role_tooltip')
                    : undefined
                }
              />
            )}

            <ActionMenuItem
              label={t('auction:header_button_place_bid')}
              onClick={onPlaceBidClick}
              Icon={IconPlus}
              disabled={!canPlaceBid}
              tooltip={
                !canPlaceBid
                  ? t('auction:header_place_bid_missing_role_tooltip')
                  : undefined
              }
            />

            <ActionMenuItem
              label={t('auction:header_button_end_now')}
              onClick={openEndModal}
              Icon={IconCrossCircle}
              textColor="warning"
              disabled={!canEndAuction}
              tooltip={
                !canPlaceBid
                  ? t('auction:header_end_auction_missing_role_tooltip')
                  : undefined
              }
            />

            <Divider />
          </>
        )}

        <ActionMenuItem
          label={isLoading ? t('core:preparing_pdf') : t('core:export_pdf')}
          onClick={exportPdf}
          Icon={IconDownload}
        />

        <ActionMenuItem
          label={t('core:send_escalation_mail')}
          onClick={sendEscalationEmail}
          Icon={IconMail}
        />
      </Flex>
    </Dropdown>
  )
}
