import { zodResolver } from '@hookform/resolvers/zod'
import { putAuctionBid } from 'api/driverama/auctions/auctionsBids'
import { AuctionSearchResponseAggItem } from 'api/driverama/auctions/searchAuctionsAgg'
import { operations } from 'api/driverama/generated/cars'
import {
  usePartnerMapper,
  usePartnersList
} from 'api/driverama/partners/search'
import { Flex } from 'driverama-core/components/Flex'
import { InputNumberNullable } from 'driverama-core/components/inputNumber/InputNumberNullable'
import { useCloseModalContext } from 'driverama-core/components/modal/Modal.utils'
import { Select } from 'driverama-core/components/select/Select'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { TextHeader } from 'driverama-core/components/text/Text'
import { toast } from 'driverama-core/components/toast/Toast'
import { useScrollToError } from 'driverama-core/utils/hooks'
import { getRemainingTime } from 'driverama-core/utils/time'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { logEvent } from 'utils/analytics'
import {
  SActions,
  SButton,
  SContainer,
  SInput,
  SPayRow,
  SRow,
  SSplitInput,
  SSubtitle
} from './AuctionsModal.styled'
import {
  BID_MAX,
  BID_MIN,
  getCurrencyOptions,
  useModalFormValidationSchema
} from './AuctionsModal.utils'

interface Props {
  onSubmitSuccess: () => void
  auctionId: string
  plannedEndAt?: string | null
  validBids?: AuctionSearchResponseAggItem['validBids']
}

type FormValues = {
  bid: number | null
  expectedPrice: number | null
  currency: operations['createOrUpdateBid']['requestBody']['content']['application/json']['sellingPriceCurrencyCode']
  partnerId: string | null
}

export function AuctionsModal({
  onSubmitSuccess,
  auctionId,
  plannedEndAt
}: Props) {
  const { t } = useTranslation(['auction', 'core'])
  const { schema, errorMap } = useModalFormValidationSchema()

  const form = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      bid: null,
      currency: 'EUR',
      expectedPrice: null,
      partnerId: null
    },
    resolver: zodResolver(schema, { errorMap })
  })

  const [bidValue, partnerId] = form.watch(['bid', 'partnerId'])

  const partnerMapper = usePartnerMapper()
  const partnerList = usePartnersList()
  const partnersOptions =
    partnerList.data?.content.map(partner => ({
      value: partner.erpId,
      label: partnerMapper(partner.erpId) ?? partner.name
    })) ?? []

  useScrollToError(form.formState.errors)

  const closeModal = useCloseModalContext()

  const onSubmit = async (data: FormValues) => {
    try {
      // TODO: calculate values for loging too
      // TODO: change currency when lang switching is ready
      if (data.bid) {
        if (plannedEndAt) {
          logEvent('bid_placed', {
            auction_id: auctionId,
            remaining_time: getRemainingTime(plannedEndAt),
            price: data.bid,
            currency: data.currency || 'EUR'
          })
        }

        if (data.partnerId) {
          await putAuctionBid(auctionId, {
            price: data.bid,
            partnerId: data.partnerId,
            sellingPrice: !!data.expectedPrice ? data.expectedPrice : undefined,
            sellingPriceCurrencyCode: data.expectedPrice
              ? data.currency
              : undefined
          })
        }
      }

      onSubmitSuccess()
      closeModal()
    } catch (error) {
      const errMessage =
        error.response?.json?.message?.[0] ?? t('core:error_api_title')

      toast({ type: 'error', content: errMessage, error })
    }
  }

  return (
    <FormProvider {...form}>
      <SContainer onSubmit={form.handleSubmit(onSubmit)}>
        <TextHeader variant="h3" as="h3">
          {t('auction:modal_title_new')}
        </TextHeader>

        <Spacer axis="vertical" size={4} />

        <SSubtitle>{t('auction:modal_subtitle_new')}</SSubtitle>

        <Spacer axis="vertical" size={8} />

        <SInput>
          <Flex variant="column" gap={2}>
            <Controller
              name="bid"
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <InputNumberNullable
                  name="bid"
                  label={t('auction:modal_input_label', {
                    min: BID_MIN,
                    max: BID_MAX
                  })}
                  value={value}
                  onChange={onChange}
                  error={form.formState.errors.bid?.message}
                />
              )}
            />

            <Select
              name="partnerId"
              label={t('auction:modal_company_label')}
              options={partnersOptions}
              onChange={newValue => {
                if (newValue === process.env.NEXT_PUBLIC_BIDDER_ID) {
                  form.setValue('currency', 'EUR')
                }
              }}
              emptyLabel={t('auction:modal_company_label')}
            />
          </Flex>

          <Spacer axis="vertical" size={4} />

          <Flex variant="column" gap={1}>
            <SRow size="small">
              <strong>{t('auction:modal_seller_gets')}</strong>
              <span>{t('auction:price', { price: bidValue ?? 0 })}</span>
            </SRow>
            <SRow size="small">
              <span>{t('auction:modal_fee')}</span>
              <span>{t('auction:price', { price: 0 })}</span>
            </SRow>
            <SPayRow variant="h5" as="span">
              <span>{t('auction:modal_you_will_pay')}</span>
              <span>{t('auction:price', { price: bidValue ?? 0 })}</span>
            </SPayRow>
          </Flex>

          <Spacer size={4} />

          <Flex variant="row" gap={2}>
            <SSplitInput variant="large">
              <Controller
                name="expectedPrice"
                control={form.control}
                render={({ field: { value, onChange } }) => (
                  <InputNumberNullable
                    name="expectedPrice"
                    label={t('auction:modal_sellect_expected_price')}
                    value={value}
                    onChange={onChange}
                    error={form.formState.errors.expectedPrice?.message}
                  />
                )}
              />
            </SSplitInput>

            <SSplitInput variant="small">
              <Select
                name="currency"
                label={t('auction:modal_seller_currency')}
                options={getCurrencyOptions(partnerId)}
                emptyLabel={t('auction:modal_seller_currency')}
              />
            </SSplitInput>
          </Flex>
        </SInput>

        <Spacer axis="vertical" size={8} />

        <SButton
          variant="secondary"
          disabled={form.formState.isSubmitting}
          type="submit"
        >
          {t('auction:modal_confirm')}
        </SButton>
        <Spacer axis="vertical" size={4} />
        <SActions>
          <SButton variant="outline" onClick={() => closeModal()}>
            {t('auction:modal_cancel')}
          </SButton>
          {/* {isEdit && (
          <SRedButton
            variant="outline"
            onClick={onRemove}
            disabled={isRemoving}
          >
            {t('auction:modal_remove')}
          </SRedButton>
        )} */}
        </SActions>
      </SContainer>
    </FormProvider>
  )
}
