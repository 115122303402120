import { QUERY_KEYS } from 'constants/queryKeys'
import { QueryClient } from 'react-query'
import { AuctionListData } from 'sections/auctions/Auctions.utils'
import { AuctionsItem } from 'sections/auctions/item/AuctionsItem'
import { SList } from './AuctionsList.styled'

interface Props {
  data: AuctionListData[]
  selected: AuctionListData | undefined
  onSelected: (value: AuctionListData) => void
}

const queryClient = new QueryClient()

export function AuctionsList({ data, selected, onSelected }: Props) {
  const onSelectedItemChange = async (item: AuctionListData) => {
    onSelected(item)

    // refresh car detail query
    await queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.carDetail(item.carId), item.state]
    })
  }

  return (
    <SList>
      {data.map(item => (
        <AuctionsItem
          key={item.id}
          isActive={item.id === selected?.id}
          onClick={() => onSelectedItemChange(item)}
          {...item}
        />
      ))}
    </SList>
  )
}
