import { css } from '@emotion/react'
import { SearchAuctionBidResponse } from 'api/driverama/auctions/auctionsBids'
import {
  PartnersSearchResponse,
  usePartnerMapper
} from 'api/driverama/partners/search'
import { Flex } from 'driverama-core/components/Flex'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { Tooltip } from 'driverama-core/components/tooltip/Tooltip'
import IconInfo from 'driverama-core/images/icons/IconCircleInfo.svg'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { SDetailsBid } from './AuctionHeaderDetailsBid.styled'

type Props = {
  variant: 'desktop' | 'mobile'
  highestBid?: SearchAuctionBidResponse['content'][number]
  highestBidPartner?: PartnersSearchResponse['content'][number]
}

export function AuctionHeaderDetailsBid({
  variant,
  highestBid,
  highestBidPartner
}: Props) {
  const { t } = useTranslation(['core', 'auction'])
  const partnerMapper = usePartnerMapper()

  if (!highestBid) {
    return null
  }

  if (variant === 'desktop') {
    return (
      <SDetailsBid
        variant="column"
        gap={1}
        align="end"
        initial={{ height: 0, overflow: 'hidden' }}
        animate={{
          height: 'auto'
        }}
        transition={{
          duration: 0.3,
          ease: 'easeInOut'
        }}
      >
        <Flex variant="row" gap={2}>
          <TextHeader variant="h6" as="h6">
            {t('core:price', {
              price: highestBid.price
            })}
          </TextHeader>

          <TextBody>
            {t('auction:detail_header_bid', {
              partner: partnerMapper(highestBidPartner?.erpId)
            })}
          </TextBody>
        </Flex>

        <TextBody>
          {t('auction:header_bid_incl_fee', {
            fee: highestBid.fee
          })}
        </TextBody>
      </SDetailsBid>
    )
  }

  return (
    <motion.div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${size(1)};
      `}
      initial={{ height: 0, overflow: 'hidden' }}
      animate={{
        height: 'auto'
      }}
      transition={{
        duration: 0.3,
        ease: 'easeInOut'
      }}
    >
      <Flex variant="row" gap={3} align="center">
        <TextHeader variant="h6" as="h6">
          {t('core:price', {
            price: highestBid.price
          })}
        </TextHeader>

        <Tooltip
          content={t('auction:header_bid_incl_fee', {
            fee: highestBid.fee
          })}
          css={css`
            margin-left: ${size(2)};
          `}
        >
          <IconInfo
            css={css`
              color: ${color('black')};
              margin-left: -${size(2)};
              width: ${size(5)};
              height: ${size(5)};
              vertical-align: text-bottom;
            `}
          />
        </Tooltip>
      </Flex>

      <TextBody>
        {t('auction:detail_header_bid', {
          partner: partnerMapper(highestBidPartner?.erpId)
        })}
      </TextBody>
    </motion.div>
  )
}
